<template>
  <v-app>
    <v-app-bar app light color="green lighten-5" class="header" elevate-on-scroll
      v-if="$route.meta.requiresAdmin || $route.meta.requiresAuth">
      <v-app-bar-nav-icon v-if="authenticated" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <span v-if="$vuetify.breakpoint.mdAndUp">{{ $route.meta.description }}</span>

      <v-spacer></v-spacer>

      <div v-if="authenticated">
        <ComplaintNotifications />
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              class="mr-3"
              v-bind="attrs"
              to="/notifications"
            >
              <Notifications />
            </v-btn>
          </template>
          <span>Notifications</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" class="mr-2" v-bind="attrs" @click="logoutUser">
              <v-icon>mdi-lock-outline</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>

        <Avatar :person="user" :class="'alt'" />
      </div>
      <span v-else>
        <v-btn small class="mr-1" to="/" text>
          <span class="mr-1">Home</span>
          <v-icon>mdi-home-outline</v-icon>
        </v-btn>

        <v-btn small text to="/login">
          <span class="mr-1">Login</span>
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </span>
    </v-app-bar>

    <v-navigation-drawer v-if="
      authenticated && ($route.meta.requiresAdmin || $route.meta.requiresAuth)
    " v-model="drawer" floating app dark>
      <div class="esupport-header py-3 px-2 pb-2">
        <!-- <v-toolbar-title class="mb-3">
          <h2>E-Support</h2>
        </v-toolbar-title> -->

        <div class="d-flex align-center justify-center mt-3 mb-5">
          <v-img class="shrink mr-2" contain src="/favicon.png" transition="scale-transition" width="40" />
          <h2>e-support</h2>
        </div>

        <SidebarSearch :authenticated="authenticated" />
      </div>

      <v-list dense nav>
        <v-list-item-group active-class="green--text text--accent-4">
          <div v-if="authenticated">
            <v-list-item class="mb-1" to="/assigned">
              <v-list-item-icon>
                <v-icon>mdi-account-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Assigned</v-list-item-title>
            </v-list-item>

            <v-list-item class="mb-1" to="/pinned">
              <v-list-item-icon>
                <v-icon>mdi-pin-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Pinned </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small class="grey--text text--lighten-2">{{
                  pinned.length
                }}</v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="mb-1" to="/complaints">
              <v-list-item-icon>
                <v-icon>mdi-alarm-light-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Complaints</v-list-item-title>
            </v-list-item>

            <v-list-item class="mb-1" to="/treated">
              <v-list-item-icon>
                <v-icon>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Treated</v-list-item-title>
            </v-list-item>
          </div>

          <div v-if="administrator">
            <v-divider class="my-3"></v-divider>
            <v-list-item class="mb-1" to="/staff">
              <v-list-item-icon>
                <v-icon>mdi-account-group-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Staff</v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>

        <v-list-group prepend-icon="mdi-tune" no-action v-if="administrator">
          <template v-slot:activator>
            <v-list-item-title>Settings</v-list-item-title>
          </template>

          <v-list-item class="mb-1" @click.prevent="openCategoriesSetting">
            <v-list-item-content>
              <v-list-item-title>Categories & Labels</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item class="mb-1" to="/notifications">
            <v-list-item-content>
              <v-list-item-title>Action Log</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn text block @click="logoutUser">
            Logout <v-icon class="ml-2" small>mdi-lock-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main id="router-view">
      <v-container class="pt-7 pb-5" dense cols="12">
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <div class="footer">
        <span v-if="$vuetify.breakpoint.lg">
          © {{ new Date().getFullYear() }}
          <span class="hidden-xs">University of Uyo</span>
          <span v-if="authenticated">
            |
            <strong class="ml-1">
              {{ user.firstName }} {{ user.lastName }} &mdash; @{{
  user.username
              }}
            </strong>
          </span>
        </span>

        <span class="contact">
          <router-link to="/" class="mr-2">Home</router-link>
          <a href="https://uniuyo.edu.ng/eportals">E-Portals</a>
        </span>
      </div>
    </v-footer>

    <Alert />
    <Snackbar />
    <Requesting />
    <Categories />
  </v-app>
</template>

<script>
import Alert from "./components/utils/Alert";
import Avatar from "./components/utils/Avatar";
import Snackbar from "./components/utils/SnackBar";
import Requesting from "./components/utils/Requesting";
// import Notifications from "./components/utils/Notifications";
import ComplaintNotifications from "./components/utils/ComplaintNotifications";
import Categories from "./components/Dialogs/CategoriesAndLabels";
import SidebarSearch from "./components/includes/SidebarSearch";

export default {
  name: "App",

  components: {
    Alert,
    Avatar,
    Snackbar,
    Requesting,
    // Notifications,
    ComplaintNotifications,
    Categories,
    SidebarSearch,
  },

  data: () => ({
    user: {
      id: null,
      type: null,
      firstName: null,
      lastName: null,
    },
    authenticated: false,
    administrator: false,
    drawer: true,
    pinned: [],
  }),

  methods: {
    async logoutUser() {
      const authenticated = !!localStorage.getItem("esAuth");
      if (authenticated) {
        await this.$axios.post("/logout");
      }
      localStorage.removeItem("esAuth");
      localStorage.removeItem("esUser");

      if (this.$router.currentRoute.name != "login") {
        this.$router.push({ name: "login" });
      }

      this.refereshAuth();
    },
    refereshAuth() {
      const authenticated = !!localStorage.getItem("esAuth");
      this.authenticated = authenticated;

      if (authenticated) {
        this.user = JSON.parse(localStorage.getItem("esUser"));
        this.administrator =
          this.user.type == "admin" || this.user.type == "root";

        this.fetchPinnedIssues();
        this.setupSocketListen();
      } else {
        localStorage.removeItem("esAuth");
        localStorage.removeItem("esUser");
        this.user = {
          role: 0,
          type: null,
          active: false,
          verified: false,
          name: "",
        };
      }
    },
    openCategoriesSetting() {
      this.$bus.$emit("settings.categories");
    },
    setupSocketListen() {
      // const KEY = process.env.VUE_APP_SOCKET_KEY;
      // const CLUSTER = process.env.VUE_APP_SOCKET_CLUSTER;
      // const pusher = new window.Pusher(KEY, {
      //   cluster: CLUSTER,
      // });
      // const channel = pusher.subscribe(`User-${this.user.id}`);
      // // console.log(channel);
      // channel.bind("notification", function (data) {
      //   console.log(JSON.stringify(data));
      // });
      // // Pusher.logToConsole = true;
      // // var pusher = new Pusher("8e3459bbc4937513c73b", {
      // //   cluster: "eu",
      // // });
      // // var channel = pusher.subscribe("my-channel");
      // // channel.bind("my-event", function (data) {
      // //   app.messages.push(JSON.stringify(data));
      // // });
    },
    async fetchPinnedIssues() {
      const { data: pinned } = await this.$axios.get(`/pinned-brief`);
      this.pinned = pinned;
    },
  },

  mounted() {
    // this.$channel.bind("registered", function (data) {
    //   console.log(JSON.stringify(data));
    // });

    this.refereshAuth();

    this.$bus.$on("login", this.refereshAuth);
    this.$bus.$on("logout", this.logoutUser);
    this.$bus.$on("complaint.pinned", this.fetchPinnedIssues);
  },
};
</script>


<style lang="scss">
html,
body,
.v-application {
  line-height: 1.5;
  scroll-behavior: smooth;
  scroll-padding-top: 2em;

  background: #eee;
  // overflow-x: hidden;

  // font-family: "Noto Sans", sans-serif !important;
  // font-family: 'Noto Serif', serif;
  font-family: 'Inter', sans-serif;

  .option {
    p {
      margin-bottom: 0 !important;
    }
  }
}

.vel-modal {
  z-index: 999999999998;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 1);
}

// .theme--light.v-application {
//   background: #f2f8f3 !important;
// }

.v-card>*:first-child:not(.v-btn):not(.v-chip):not(.v-avatar),
.v-card>.v-card__progress+*:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.esupport-header {
  background-color: #eff8f2;
  background-color: #0f5326;
  // border-right: 1px solid #8eb49c;

  h2 {
    font-size: 1.75em;
    color: #ffffff;
  }
}

.v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  height: 100% !important;
}

.header {
  // background-color: #46d234;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='11' height='11' fill-opacity='0.6' fill='%2351ff58'/%3E%3C/svg%3E");
  // background-attachment: fixed;
  // background-size: cover;

  // background: linear-gradient(90deg, #64ec52 40%, #ffffff 0);
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;

  background: transparent;
  // max-height: 45px;
  // padding-top: 5px;

  h2 {
    color: #ffffff;
    color: #0a4e23;
  }

  .logo-wrapper {
    padding: 0.6em 0.45em 0.3em;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.message {
  white-space: pre-line;
}

.footer {
  width: 100%;
  display: flex;
  font-size: 0.75em;

  .contact {
    margin-left: auto;

    a {
      text-decoration: none;
    }
  }
}

.no-word-break {
  hyphens: none;
}

.help-tip {
  position: absolute;
  top: 18px;
  right: 18px;
  text-align: center;
  background-color: #bcdbea;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  cursor: default;
}

.help-tip:before {
  content: "?";
  font-weight: bold;
  color: #fff;
}

.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;

  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

.help-tip p {
  /* The tooltip */
  display: none;
  text-align: left;
  background-color: #1e2021;
  padding: 20px;
  width: 300px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  right: -4px;
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
}

.help-tip p:before {
  /* The pointer of the tooltip */
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #1e2021;
  right: 10px;
  top: -12px;
}

.help-tip p:after {
  /* Prevents the tooltip from being hidden */
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

/* CSS animation */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.fade-enter-active {
  transition: all 0.5s ease;
}

// .fade-leave-active {
//   transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
// }

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(10px);
}
</style>
