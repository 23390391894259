<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="slide-y-transition" :nudge-width="80" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="brown" small rounded dark class="ma-1" v-bind="attrs" v-on="on">
        Options
        <v-icon small right>mdi-dots-horizontal-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <h4 class="pa-4">OPTIONS</h4>
      <v-divider></v-divider>
      <v-list>
        <v-list-item class="pt-3">
          <v-autocomplete dense v-model="specimen.category_id" :items="categories" item-value="id"
            item-text="description" outlined label="Category" prepend-inner-icon="mdi-tag-outline"></v-autocomplete>
        </v-list-item>
        <v-list-item>
          <v-autocomplete dense v-model="specimen.user_id" :items="staff" item-text="fullName" item-value="id" outlined
            label="Assigned to" prepend-inner-icon="mdi-account-check-outline"></v-autocomplete>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-action>
            <v-switch v-model="account.isActive" color="teal"></v-switch>
          </v-list-item-action>
          <v-list-item-title>
            {{ account.isActive ? "Active" : "Inactive" }}
            Account
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="account.deleted" color="red"></v-switch>
          </v-list-item-action>
          <v-list-item-title>
            {{ account.deleted ? "Deleted" : "Not Deleted" }}
          </v-list-item-title>
        </v-list-item> -->
      </v-list>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <!-- <v-btn text color="red" @click="menu = false"> Cancel </v-btn> -->
        <v-btn text color="green" @click="updateComplaint">
          Save changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    issue: {
      type: Object,
      default: () => { },
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    account: {},
    specimen: {},
    staff: [],
    categories: [],
  }),
  watch: {
    issue: {
      handler: function (newVal) {
        this.specimen = { ...newVal };
      },
      deep: true,
    },
  },
  computed: {
    isRoot: () => {
      const loggedIn = !!localStorage.getItem("itfAuth");
      return (
        loggedIn &&
        JSON.parse(localStorage.getItem("itfUser"))["type"] == "Root"
      );
    },
  },
  methods: {
    updateComplaint() {
      this.$axios({
        url: `issues/${this.specimen.id}`,
        method: "PUT",
        data: {
          ...this.specimen,
        },
      })
        .then(() => {
          if (this.account.deleted === true) {
            this.$router.back();
            this.$bus.$emit("snackbar", {
              type: "info",
              message: "Complaint deleted",
            });
          } else {
            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Complaint updated",
            });
          }

          this.$bus.$emit("complaint.updated");

          // this.$io.emit("mentors_updated");
          this.menu = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteProfile() {
      if (confirm("Do you really want to delete profile?")) {
        this.$axios({
          url: `identity/${this.personal.id}`,
          method: "DELETE",
        })
          .then(() => {
            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Complaint deleted",
            });
            this.$router.back();
          })
          .catch((error) => {
            console.error(error);
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Complaint delete failed",
            });
          });
      }
    },
    fetchUsers() {
      this.$axios({
        url: `users`,
        method: "GET",
      })
        .then(({ data: users }) => {
          this.staff = users.map((user) => {
            user.fullName = `${user.firstName} ${user.lastName} — @${user.username}`;
            return user;
          });
        })
        .catch((error) => {
          console.error(error);
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not load staff",
          });
        });
    },
    fetchCategories() {
      this.$axios({
        url: "/categories",
        method: "GET",
      }).then(({ data: categories }) => {
        this.categories = categories;
      });
    },
  },

  created() {
    this.fetchUsers();
    this.fetchCategories();
    this.$bus.$on("categories.updated", this.fetchCategories);
  },
};
</script>
