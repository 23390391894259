<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat dark color="green darken-4">
          <!-- <v-btn color="green lighten-3" icon> -->
          <!-- <v-icon>mdi-tune</v-icon> -->
          <!-- </v-btn> -->
          <v-toolbar-title>
            <v-icon class="mr-3">mdi-tune</v-icon>
            <span class="lime--text lighten-4">Settings / </span>
            Categories & Labels
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialog = false">
              Close
              <v-icon class="ml-2">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row>
          <v-col cols="5">
            <Categories />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Categories from "./Includes/Categories";

export default {
  components: {
    Categories,
  },

  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },

  methods: {
    openCategories() {
      this.dialog = true;
    },
  },

  mounted() {
    this.$bus.$on("settings.categories", this.openCategories);
  },
};
</script>