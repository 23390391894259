<template>
  <div class="avatar" :class="type">
    <strong>{{ formatNameForIcon(person) }}</strong>
  </div>
</template>

<script>
import { formatNameForIcon } from "../../tools/format";
export default {
  props: {
    person: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
    },
  },
  methods: {
    formatNameForIcon,
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background-color: #ffaac7;
  text-align: center;
  border-radius: 5px;

  align-items: center;
  justify-content: center;

  user-select: none;

  transition: all 200ms ease;

  strong {
    color: #5f0f34;
    letter-spacing: 0;
    text-decoration: none;
  }

  &:hover {
    background-color: #7fffd4;

    strong {
      color: #3a0920;
    }
  }
}

.avatar.alt {
  background-color: #1a7e3b;

  strong {
    color: #ffffff;
  }
}
</style>