<template>
  <div>
    <h1 class="mb-8">
      <v-btn @click.prevent="$router.back()" outlined color="green darken-2">
        <v-icon left>mdi-chevron-left</v-icon> Complaints
      </v-btn>
      <span class="grey--text">&nbsp;/&nbsp;</span>{{ issue.subject }}
      <br>
      <v-chip v-if="issue.category" color="blue-grey lighten-2" small outlined label class="my-1 font-weight-regular">
        <v-icon left> mdi-tag-outline </v-icon>
        {{ issue.category.description }}
      </v-chip>
    </h1>



    <!-- <v-divider class="mt-2 mb-4"></v-divider> -->

    <div class="d-flex grey lighten-4 align-center py-2 px-2 mb-7">
      <div>
        <v-btn small v-if="issue.registration" color="orange" title="Click to show complaints belonging to this student"
          class="mr-1" @click="openStudentComplaints">
          <v-icon left> mdi-account-outline </v-icon>
          {{ issue.registration }}
        </v-btn>

        <v-chip outlined label class="ma-1">
          <v-icon left> mdi-ticket-outline </v-icon>
          {{ issue.ticket | VMask("XXX-XXX") }}
        </v-chip>

        <v-chip outlined label class="ma-1">
          <v-icon left> mdi-phone-outline </v-icon>
          {{ issue.contact }}
        </v-chip>

        <v-chip v-if="issue.user" outlined label class="ma-1"
          :title="`Assigned to ${issue.user.firstName} ${issue.user.lastName}`">
          <v-icon left> mdi-account-check-outline </v-icon>
          {{ issue.user.firstName }} {{ issue.user.lastName }}
        </v-chip>
      </div>

      <v-spacer></v-spacer>

      <div :class="loading ? 'd-none' : ''" class="groupings">

        <ComplaintSetting :issue="issue" />

        <v-btn small depressed rounded outlined class="ma-1" :class="pinned ? 'white--text' : ''"
          :color="pinned ? 'purple lighten-1' : 'grey'" @click="pinIssue(pinned)" :disabled="pinning"
          :loading="pinning">
          {{ pinned? "Pinned": "Not pinned" }}
          <v-icon right x-small v-if="pinned">mdi-pin-outline</v-icon>
          <v-icon right x-small v-else>mdi-pin-off-outline</v-icon>
        </v-btn>

        <v-btn :disabled="loading" depressed small rounded class="ma-1"
          :title="`Click to mark complaint as ${issue.status == 'treated' ? 'untreated' : 'treated'}`"
          :class="issue.status == 'treated' ? 'white--text' : ''"
          :color="`${issue.status == 'treated' ? 'green darken-1' : 'grey lighten-2'}`"
          @click.prevent="toggleIssueStatus">
          <small class="mr-1">Status:</small> {{ issue.status }}
          <!-- <v-icon right small v-if="issue.status == 'open'">mdi-close</v-icon>
          <v-icon right small v-else-if="issue.status == 'treating'">mdi-close</v-icon>
          <v-icon right small v-else>mdi-check</v-icon> -->
        </v-btn>

        <!-- <label for="">
          <v-switch class="d-inline" v-model="statusSwitch" inset :label="`Status: ${issue.status}`"></v-switch>
        </label> -->

        <v-btn v-if="issue.status !== 'treated'" :disabled="loading" depressed small rounded @click="closeIssue"
          color="red" dark class="ma-1" title="Remove from queue permanently">
          Discard
        </v-btn>
      </div>
    </div>

    <!-- <v-divider class="mt-1 mb-7"></v-divider> -->

    <Fetching v-if="loading" />

    <v-row v-else>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="7" order="last" order-md="first">
            <div class="teal lighten-5 pa-3">
              <h4 class="mb-2 teal--text text--darken-3 text-uppercase">
                Complaint description
              </h4>
              <v-divider class="mb-2"></v-divider>
              <p class="message">
                {{ issue.description }}
              </p>
            </div>
            <p class="mt-2 d-flex justify-space-between">
              <small>
                <v-chip x-small>
                  <v-icon small left>mdi-eye</v-icon>
                  <strong>{{ issue.views }} times</strong>
                </v-chip>
              </small>
              <small>Last Updated: {{ formatDate(issue.updated_at) }}</small>
            </p>
            <Comments :complaint="issue.id" />
          </v-col>
          <v-col cols="12" md="5">
            <v-card color="blue lighten-5" flat class="mb-3">
              <v-card-text>
                <h3>Admin User Guide <v-chip color="red" dark x-small>Attention</v-chip></h3>
                <ol style="margin-top: 0.75em; padding-left: 1em">
                  <li class="my-2" v-if="issue.status === 'treated'">
                    If you want re-open this complaint, click the STATUS button to mark it as untreated.
                  </li>
                  <li class="my-2" v-else>
                    After you have completely treated this complaint, please click the STATUS button to mark it as
                    TREATED.
                    <br />
                    This will automatically add a comment letting the complainant know that their issue
                    has been treated. They will then have 24 hours to reopen the complaint before it closes
                    permanently.
                  </li>

                  <li class="my-2" v-if="issue.status !== 'treated'">
                    If you want to close the issue permanently (without automatically adding a comment and giving
                    the option of reopening), click the DISCARD button.
                  </li>

                  <li class="my-2" v-if="pinned">
                    To unpin this complaint click the PINNED button.
                  </li>
                  <li class="my-2" v-else>
                    If you would like to easily keep track of this complaint even if it is not assigned to you, click
                    the NOT PINNED button and it will be added to your pinned complaints for quick reference.
                  </li>
                </ol>
              </v-card-text>
            </v-card>
            <Attachments :attachments="issue.attachments" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Fetching from "../utils/Fetching.vue";
import Comments from "./Includes/Comments";
import Attachments from "./Includes/Attachments";
import ComplaintSetting from "./Includes/ComplaintSetting";
import { formatDate } from "../../tools/format";

export default {
  props: ["ticket"],

  components: {
    Fetching,
    Comments,
    Attachments,
    ComplaintSetting,
  },

  data: () => ({
    issue: {
      attachments: [],
      updated_at: new Date(),
    },
    pinned: false,
    loading: false,
    pinning: false,

    // statusSwitch: false
  }),

  methods: {
    formatDate,
    openStudentComplaints() {
      this.$router.push({ name: 'student-complaints', params: { registration: this.issue.registration } });
    },
    fetchComplaint() {
      this.loading = true;
      this.$axios
        .get(`/issue/${this.ticket}`)
        .then(async ({ data: issue }) => {
          this.issue = issue;

          const {
            data: { status: pinned },
          } = await this.$axios.get(`/pinned-status?issue=${issue.id}`);

          this.pinned = pinned || false;
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not load issue",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleIssueStatus() {
      this.loading = true;
      this.$axios
        .patch(`/issue/${this.issue.id}/status`)
        .then(() => {
          this.fetchComplaint();
          this.$bus.$emit("complaint.updated");
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: `Could not ${this.issue.status == "open" ? "close" : "open"
              } issue`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeIssue() {
      this.loading = true;
      this.$axios
        .patch(`/issue/${this.issue.id}/close`)
        .then(() => {
          this.fetchComplaint();
          this.$bus.$emit("complaint.updated");
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error", message: "Could not close issue",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pinIssue(status) {
      this.pinning = true;
      this.$axios
        .post(`/pinned`, {
          issue_id: this.issue.id,
        })
        .then(async () => {
          const {
            data: { status: pinned },
          } = await this.$axios.get(`/pinned-status?issue=${this.issue.id}`);
          this.pinned = pinned || false;

          this.$bus.$emit("complaint.pinned");
          this.$bus.$emit("snackbar", {
            type: "info",
            message: `Issue has been ${status ? "unpinned" : "pinned"}`,
          });
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not pin issue",
          });
        })
        .finally(() => {
          this.pinning = false;
        });
    },
  },

  mounted() {
    this.fetchComplaint();

    this.$bus.$on("complaint.updated", this.fetchComplaint);
  },
};
</script>

<style scoped>
.groupings {
  top: 6em;
  position: sticky;
}

.complaint-info {
  margin-right: 0.5em;
}

.complaint-info+.complaint-info {
  border-left: 1px solid #d4e;
  padding-left: 0.5em;
}
</style>
