import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    issues: [],
    pinned: [],
  },
  mutations: {
    populateComplaints(state, issues) {
      state.issues = issues;
    },
    populatePinnedComplaints(state, pinned) {
      state.pinned = pinned;
    },
    clearComplaints(state) {
      state.issues = [];
    },
  },
});

export default store;
