<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h4 class="grey--text text--darken-1">Make comment and/or additional document</h4>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form @submit.prevent="postComment" v-model="valid">
          <v-textarea outlined rows="3" label="Type new comment here" prepend-inner-icon="mdi-message-outline"
            counter="300" :rules="rules.statement" v-model="comment" single-line class="mb-0"></v-textarea>

          <p class="grey--text">
            If you have more than one file to attach, merge them using
            <v-btn href="https://www.ilovepdf.com/merge_pdf" outlined color="orange" target="_blank" x-small>This
              tool</v-btn>
            or others like it.
          </p>

          <v-file-input outlined accept="image/*,application/pdf" label="Additional document (optional)"
            placeholder="Attach document" :show-size="1000" prepend-icon="" prepend-inner-icon="mdi-attachment"
            v-model="attachment" :rules="rules.attachment"></v-file-input>

          <v-btn type="submit" color="green" outlined :loading="creating" :disabled="!comment || creating">
            Post Comment
          </v-btn>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { statement, attachment } from "../../tools/rules";

export default {
  props: ["ticket"],

  data: () => ({
    valid: false,
    comment: null,
    attachment: null,
    comments: [],

    rules: {
      statement, attachment
    },

    creating: false,
    loading: false,
  }),

  watch: {
    attachment(val) {
      if (!!val && !this.comment) {
        this.comment = "Please find attached.";
      }
    }
  },

  methods: {
    postComment() {
      this.creating = true;

      var formData = new FormData();
      if (this.attachment) formData.append("attachment", this.attachment);
      formData.append("ticket", this.ticket);
      formData.append("text", this.comment);

      this.$axios({
        url: "/issues/comment",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.creating = false;

          this.comment = null,
            this.attachment = null,

            this.$emit('comment');
        })
        .then(() => {
          this.creating = false;
        });
    },
  }
};
</script>
