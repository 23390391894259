<template>
  <div>
    <!-- <h1>Staff</h1> -->

    <v-card max-width="750" class="my-6" color="basil">
      <v-toolbar color="pink darken-4" class="white--text">
        <v-toolbar-title>
          Portal Staff
          <!-- ({{ staff.length }})  -->
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn small to="/register">Register Staff</v-btn>
        <v-btn icon @click="searching = !searching" :color="searching ? 'yellow' : 'pink lighten-4'">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>

      <Fetching v-if="loading" />

      <div v-else>
        <transition name="fade">
          <div v-if="searching" class="py-3 px-4 mt-3">
            <v-text-field prepend-inner-icon="mdi-account" label="Search staff list" v-model="term" clearable
              solo></v-text-field>
          </div>
        </transition>

        <v-list three-line>
          <div v-for="(person, index) in staff" :key="index" class="staff">
            <v-divider v-if="index != 0" inset></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <router-link style="text-decoration: none" :to="`/staff-details/${person.username}`">
                  <Avatar :person="person" />
                </router-link>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="d-flex">
                  {{ person.firstName }} {{ person.lastName }}
                  <v-spacer></v-spacer>
                  <v-chip x-small :title="`This staff is ${person.isActive ? '' : 'not '
                  }active`" :color="`${person.isActive ? 'light-green' : 'grey'
  } lighten-3`">
                    @{{ person.username }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <small class="text-uppercase" :class="person.type == 'admin' ? 'red--text' : ''">
                    {{ person.type }} Officer — {{ person.staffNumber }}
                  </small>
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mt-2 grey--text text--darken-2">
                  <small>
                    Profile created: {{ formatDate(person.created_at) }}
                  </small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
import Avatar from "../utils/Avatar";
import Fetching from "../utils/Fetching";
import { formatDate } from "../../tools/format";

export default {
  components: { Avatar, Fetching },

  data: () => ({
    staff: [],
    term: null,
    searching: false,
    loading: false,
  }),

  methods: {
    formatDate,
    async fetchStaff() {
      this.loading = true;
      const { data: staff } = await this.$axios.get(`/users`);
      this.staff = staff;
      this.loading = false;
    },
  },

  mounted() {
    this.fetchStaff();
  },
};
</script>
