<template>
  <v-card max-width="420px" class="login-wrapper mx-auto">
    <!-- <v-card-title>Login</v-card-title> -->
    <div class="pa-5 d-flex align-items-center">
      <v-spacer></v-spacer>
      <v-img class="shrink mr-2" contain src="/favicon.png" transition="scale-transition" width="46" />
    </div>
    <h3 class="py-1 text-center green white--text text-uppercase">Login</h3>
    <v-card class="px-5 py-8" color="basil" flat>
      <v-form class="mr-2 mt-4" v-model="valid" @submit.prevent="authenticate">
        <v-text-field outlined label="Email *" prepend-icon="mdi-at" :rules="rules.required"
          v-model="email"></v-text-field>

        <v-text-field outlined label="Password *" prepend-icon="mdi-lock-outline" :rules="rules.required"
          v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>

        <div class="ml-8">
          <p>* mark required fields.</p>
          <v-btn color="green" type="submit" class="white--text" :disabled="!valid || loading" :loading="loading">
            Submit
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { required } from "../../tools/rules";

export default {
  data: () => ({
    valid: false,
    showPassword: false,
    rules: {
      required,
    },

    email: null,
    password: null,
    loading: false,
  }),

  methods: {
    authenticate() {
      this.loading = true;
      this.$axios
        .post("/login", { email: this.email, password: this.password })
        .then(({ data: response }) => {
          const { token, user } = response;
          localStorage.setItem("esUser", JSON.stringify(user));
          localStorage.setItem("esToken", token);
          localStorage.setItem("esAuth", "true");

          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Login successful",
          });

          this.$bus.$emit("login");
          // this.$router.push({ name: "notifications" });
          this.$router.push({ name: "assigned" });
        })
        .catch((error) => {
          console.log(error);
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Bad credentials",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.picture-bg {
  background: #f5872d;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;

  border-radius: 0 5px 5px 0;

  background-image: url("https://inoyotorofoundation.org/images/2019/grand_mentor_winners_from_left_to_right_francis_ube_biology_gabriel_ukpabio_chemistry_physics_ndifreke_thomas_physics.jpg");
  background-position: center;
  background-size: cover;
}

.container {
  z-index: 2;
  position: relative;
  margin: 10vh auto auto;
}

.login-wrapper {
  // display: flex;
  // flex-direction: row;
  margin: 5% auto;
}

@media screen and (max-width: 480px) {
  .container {
    margin: 5% auto auto;
  }
}
</style>
