<template>
  <div class="mt-5 ml-2">
    <!-- <h2 class="mt-4 ml-8 mb-7">Follow Up</h2> -->

    <v-form
      class="pb-7 mr-4"
      v-model="valid"
      @submit.prevent="followTicket"
    >
      <div class="ml-8">
        Enter the ticket number that was generated for the issue you submitted.

        <v-divider class="my-3 mb-5"></v-divider>
      </div>
      <v-text-field
        label="Ticket number*"
        outlined
        v-model="ticket"
        prepend-icon="mdi-ticket-confirmation"
        :rules="rules.ticket"
        clearable
        v-mask="'XXX-XXX'"
      ></v-text-field>

      <div class="ml-8">
        <p>* mark required fields</p>
        <v-btn
          type="submit"
          color="green"
          class="white--text"
          :disabled="!ticket || !valid || loading"
          :loading="loading"
        >
          Submit
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ticket } from "../../tools/rules";

export default {
  data: () => ({
    valid: false,
    ticket: null,
    loading: false,

    rules: {
      ticket,
    },
  }),

  methods: {
    followTicket() {
      this.$router.push({ name: 'follow-ticket', params: { ticket: this.ticket.toLowerCase().split("-").join("") } })
    }
  }
};
</script>

<style lang="scss" scoped>
.complaint-tags {
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: #f7f0d2 !important;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 0.12em;

  // border-top: 1px solid #98c59b;
  // border-bottom: 1px solid #98c59b;
}
</style>
