<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" class="mr-3" v-bind="attrs" to="/assigned">
        <v-badge
          bordered
          :content="untreated.toString()"
          :color="untreated > 0 ? 'error' : 'info'"
          overlap
        >
          <v-icon>mdi-account-check-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <span v-if="untreated > 0">{{ untreated }} Untreated</span>
    <span v-else>All clear!</span>
  </v-tooltip>
</template>

<script>
export default {
  data: () => ({
    untreated: 0,
  }),

  methods: {
    fetchStaffComplaintStats() {
      this.$axios
        .get(`/staff-complaints`)
        .then(({ data: response }) => {
          const { treated, total } = response;
          this.untreated = total - treated;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.fetchStaffComplaintStats();
    this.$bus.$on("complaint.updated", this.fetchStaffComplaintStats);
  },

  // Get notifications for user

  // Listen for new notifications and update accordingly

  // Listen for login and re-populate the notifications
};
</script>