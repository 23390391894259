<template>
  <div>
    <h1 class="mb-5">All Complaints <span v-if="total">({{ total }})</span></h1>

    <ComplaintsTable :issues="issues" :pinned="pinned" :count="total" :current="currentPage" :last="lastPage"
      :from="from" :to="to" @paginate="fetchComplaints" />
  </div>
</template>


<script>
import { formatDate } from "../../tools/format";
import ComplaintsTable from "./Includes/ComplaintsTable.vue";

export default {
  components: {
    ComplaintsTable
  },

  data: () => ({
    issues: [],
    pinned: [],
    loading: false,
    term: "",

    headers: [
      { text: "Updated", value: "updated_at" },
      { text: "Ticket", value: "ticket" },
      { text: "Reg. No.", value: "registration" },
      { text: "Subject", value: "subject" },
      { text: "Status", value: "status" },
      { text: "", value: "created_at", sortable: false },
    ],

    valid: false,

    currentPage: 1,
    from: null,
    to: null,
    total: null,
    lastPage: 1,

    count: 0,
  }),

  methods: {
    formatDate,
    searchComments() { },
    fetchComplaints(page = 1) {
      this.loading = true;
      this.$axios
        .get(`/issues?page=${page}`)
        .then(async ({ data: response }) => {
          const {
            data: issues,
            current_page,
            last_page,
            from,
            to,
            total,
          } = response;

          this.currentPage = current_page;
          this.lastPage = last_page;
          this.from = from;
          this.to = to;
          this.total = total;

          this.issues = issues;
          this.loading = false;
          this.reloading = false;

          const { data: pinned } = await this.$axios.get(`/pinned-brief`);
          this.pinned = pinned;
        })
        .then(() => {
          this.loading = false;
        });
    },
    isPinned(id) {
      return !!this.pinned.find((pinned) => {
        return pinned.issue_id == id;
      });
    },
    navigateToPage() {
      this.fetchComplaints(this.currentPage);
    },
  },

  mounted() {
    this.fetchComplaints();
  },
};
</script>

<style lang="scss" scoped>
.controls {
  top: 6em;
  margin-bottom: 1em;
  position: sticky;

  .search {
    position: relative;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}
</style>
