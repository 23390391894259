<template>
  <div v-if="attachments.length > 0">
    <v-list subheader two-line class="grey lighten-3">
      <v-subheader>
        <h3 class="mt-2">Uploaded Documents ({{ attachments.length }})</h3>
      </v-subheader>

      <v-list-item v-for="(attachment) in attachments" :key="attachment.id" @click="openAttachment(attachment)">
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark>
            mdi-file
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="attachment.description"></v-list-item-title>

          <v-list-item-subtitle v-text="formatDate(attachment.created_at)"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="openAttachment(attachment)">
            <v-icon color="grey darken-1">mdi-chevron-right-circle</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

    </v-list>

    <vue-easy-lightbox v-if="selectedAttachment" escDisabled moveDisabled :visible="lightbox" @hide="handleHide"
      :imgs="`${attachmentUrl}/${selectedAttachment.location}`" :index="0"></vue-easy-lightbox>

    <v-dialog v-model="dialog" width="720px">
      <v-card v-if="selectedAttachment" class="py-0 pb-1 attachment-wrapper bg-transparent">
        <div class="mx-1 pt-3">
          <p class="pt-2 mx-1">{{ selectedAttachment.description }}</p>
          <object width="100%" class="issue-img" style="height: 80vh" type="application/pdf"
            title="Complaint attachment" :data="`${attachmentUrl}/${selectedAttachment.location}`"
            v-if="selectedAttachment.location.substr(-3) == 'pdf'"></object>

          <div v-else>
            <figure>
              <img class="issue-img" :alt="selectedAttachment.description" :title="selectedAttachment.description"
                :src="`${attachmentUrl}/${selectedAttachment.location}`" />
              <div class="tools-buttons">
                <button class="rotate-btn indigo--text text--bold" @click="rotateAttachment">Rotate &#8635;</button>
                <button @click="lightbox = !lightbox">Fullscreen</button>
              </div>
            </figure>

            <!-- <FsLightbox :lightbox="lightbox" :sources="[
              `${attachmentUrl}/${selectedAttachment.location}`
            ]" type="image" /> -->


          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>No document uploaded.</div>
</template>

<script>
import { formatDate } from "../../../tools/format";

import VueEasyLightbox from 'vue-easy-lightbox';

// import FsLightbox from "fslightbox-vue";

export default {
  data: () => ({
    dialog: false,
    selectedAttachment: null,
    image: {
      rotation: 0,
      angle: 90
    },
    lightbox: false,
    attachmentUrl: process.env.VUE_APP_URL
  }),

  // components: { FsLightbox },

  components: {
    VueEasyLightbox
  },

  props: {
    attachments: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    lightbox(showing) {
      const htmlShell = document.querySelector('html');
      if (showing) {
        htmlShell.style.height = '100%';
        htmlShell.style.overflowY = 'hidden';
      } else {
        htmlShell.style.height = '100%';
        htmlShell.style.overflowY = 'auto';
      }
    }
  },

  methods: {
    formatDate,
    openAttachment(attachment) {
      this.selectedAttachment = attachment;

      if (attachment.location.substr(-3) != 'pdf') {
        return this.lightbox = true;
      }

      this.dialog = true;
    },
    rotateAttachment() {
      const rotated = document.querySelector('.issue-img');

      this.image.rotation = (this.image.rotation + this.image.angle) % 360;
      rotated.style.transform = `rotate(${this.image.rotation}deg)`;
    },
    handleHide() {
      this.lightbox = false;
    }
  },
};
</script>

<style scoped>
.vel-modal {
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 1);
}

figure {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0 0 4px;
}

.tools-buttons {
  position: absolute;
  top: 1.25em;
  right: 1em;
  display: flex;
  gap: 1em;
}

.issue-img {
  overflow: visible;
  width: 100%;
}

.v-dialog::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
