<template>
  <div>
    <v-card max-width="750" class="my-6" color="basil">
      <v-toolbar color="pink darken-4" class="white--text">
        <v-toolbar-title>
          <div class="d-flex">
            <v-btn small to="/staff">
              <v-icon left>mdi-chevron-left</v-icon>
              Staff
            </v-btn>
            &nbsp;/ Register
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <v-form @submit.prevent="registerStaff" class="py-8 pl-4 pr-5" v-model="valid">
        <v-text-field prepend-icon="mdi-account-outline" label="First name" v-model="staff.firstName"
          :rules="rules.required" filled></v-text-field>
        <v-text-field prepend-icon="mdi-account" label="Last name" v-model="staff.lastName" :rules="rules.required"
          filled></v-text-field>
        <v-text-field prepend-icon="mdi-card-account-details-outline" label="Staff number" prefix="PF/"
          v-model="staff.staffNumber" :rules="rules.required" filled type="number" min="1000"></v-text-field>
        <v-text-field prepend-icon="mdi-account-hard-hat" label="Designation" v-model="staff.designation"
          :rules="rules.required" filled></v-text-field>
        <v-text-field prepend-icon="mdi-at" label="Email" v-model="staff.email" :rules="rules.email"
          filled></v-text-field>
        <v-text-field prepend-icon="mdi-phone-outline" label="Phone number" v-model="staff.phone" :rules="rules.phone"
          filled v-mask="'NNNN-NNN-NNNN'"></v-text-field>
        <v-text-field prepend-icon="mdi-lock-outline" label="Password" v-model="staff.password" :rules="rules.required"
          clearable filled></v-text-field>
        <div class="ml-8 mt-4">
          <v-btn :disabled="!valid || registering" :loading="registering" type="submit" color="green" outlined>Register
            Staff</v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { required, phone, email } from "../../tools/rules";
export default {
  data: () => ({
    valid: false,
    registering: false,
    staff: {
      firstName: null,
      lastName: null,
      staffNumber: null,
      designation: null,
      email: null,
      phone: null,
      password: null,
    },
    rules: {
      required,
      phone,
      email,
    },
  }),

  methods: {
    registerStaff() {
      const body = {
        ...this.staff,
        ...{ staffNumber: `PF/${this.staff.staffNumber}` },
      };

      this.registering = true;
      this.$axios
        .post(`/register`, body)
        .then(() => {
          this.$router.push({ name: "staff" });
          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Registration successful",
          });
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Registration failed",
          });
        })
        .finally(() => {
          this.registering = false;
        });
    },
  },

  mounted() {
    // this.fetchStaff();
  },
};
</script>
