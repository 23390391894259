<template>
  <v-form v-if="authenticated" @submit.prevent="searchForTicket">
    <v-text-field full-width dark placeholder="Search ticket" hide-details append-icon="mdi-magnify" filled single-line
      v-model="ticket" :disabled="loading" :loading="loading" v-mask="'XXX-XXX'"></v-text-field>
  </v-form>
</template>

<script>
export default {
  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ticket: null,
    loading: false,
  }),

  methods: {
    searchForTicket() {
      this.loading = true;
      const ticket = this.ticket.toUpperCase().split("-").join("");
      this.$axios
        .get(`/issues/ticket/${ticket}`)
        .then(({ data: issue }) => {
          this.$router.push({
            name: "complaint",
            params: { ticket: issue.ticket },
          });

          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Complaint found",
          });

          this.loading = false;
          this.ticket = null;
        })
        .catch(() => {
          this.loading = false;
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Complaint not found",
          });
        });
    },
  },
};
</script>
