<template>
  <div>
    <h2 class="px-4 mt-5 mb-4">Categories</h2>
    <v-list three-line subheader>
      <v-list-item>
        <v-list-item-content>
          <v-form @submit.prevent="addCategory">
            <v-text-field
              full-width
              placeholder="New category"
              hide-details
              prepend-inner-icon="mdi-tag-plus-outline"
              filled
              single-line
              v-model="description"
              :disabled="creating"
              :loading="creating"
            ></v-text-field>
            <v-btn
              :loading="creating"
              :disabled="!description || creating"
              type="submit"
              class="mt-3"
              color="green"
              outlined
            >
              Save category
            </v-btn>
          </v-form>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-container class="ml-1">
      <h3 class="mx-1 mt-2 mb-3">
        Available Categories
        <span class="grey--text">({{ categories.length }})</span>
      </h3>

      <v-chip
        v-for="category in categories"
        :key="category.id"
        class="ma-1"
        close
        color="teal"
        text-color="white"
        @click:close="removeCategory(category.id)"
      >
        {{ category.description }}
      </v-chip>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    description: null,
    creating: false,
    categories: [],
  }),

  methods: {
    addCategory() {
      this.$axios({
        url: "/categories",
        method: "POST",
        data: {
          description: this.description,
        },
      })
        .then(() => {
          this.description = null;
          this.fetchCategories();
          this.$bus.$emit("categories.updated");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.creating = false;
        });
    },
    removeCategory(category) {
      console.log(category);
    },
    deleteCategory() {
      if (confirm("Do you really want to delete this category?")) {
        this.$axios({
          url: `identity/${this.personal.id}`,
          method: "DELETE",
        })
          .then(() => {
            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Profile deleted",
            });
            this.$router.back();
          })
          .catch((error) => {
            console.error(error);
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Profile delete failed",
            });
          });
      }
    },
    fetchCategories() {
      this.$axios({
        url: "/categories",
        method: "GET",
      }).then(({ data: categories }) => {
        this.categories = categories;
      });
    },
  },

  mounted() {
    this.fetchCategories();
  },
};
</script>