<template>
  <div class="comments">
    <h4 class="blue--text text-uppercase">Comments ({{ comments.length }})</h4>
    <v-card class="mt-4 pa-4" v-for="comment in comments" :key="comment.id">
      <v-row class="pa-3">
        <v-chip :color="comment.user ? 'green lighten-4' : 'blue lighten-5'" label x-small>
          <v-icon left>mdi-account</v-icon>
          <span v-if="comment.user">{{ comment.user.firstName }} {{ comment.user.lastName }}</span>
          <span v-else>Complainant</span>
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn text x-small class="text-uppercase" :color="comment.type == 'public' ? 'blue' : ''">
          <strong>{{ comment.type }}</strong>
        </v-btn>
      </v-row>
      <v-divider class="mt-3"></v-divider>
      <p class="message">
        {{ comment.text }}
      </p>
      <v-divider></v-divider>
      <v-row class="pa-3 mt-1">
        <v-spacer></v-spacer>
        <v-btn text x-small>
          {{ formatDate(comment.created_at) }}
        </v-btn>
      </v-row>
    </v-card>

    <!-- <v-divider class="my-5"></v-divider> -->

    <v-form @submit.prevent="makeComment" v-model="valid" class="mt-7">
      <v-textarea outlined rows="3" label="Type new comment here" prepend-inner-icon="mdi-message-outline" counter="300"
        :rules="rules.statement" v-model="comment" single-line class="mb-0" persistent-hint :hint="`${isPublic
        ? 'This comment is now visible to everyone'
        : 'Only staff can read this comment'
        }`"></v-textarea>

      <v-row class="d-flex align-center px-3 mt-3">
        <v-checkbox
          title="Comments are usually private. Only check this box if you want to make your comment visible to the public."
          v-model="isPublic" label="Public comment" color="blue">
        </v-checkbox>
        <v-spacer></v-spacer>
        <v-btn type="submit" color="green" outlined :loading="creating" :disabled="!comment || creating">
          Post Comment
        </v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { statement } from "../../../tools/rules";
import { formatDate } from "../../../tools/format";

export default {
  props: ["complaint"],

  data: () => ({
    valid: false,
    comment: null,
    comments: [],

    rules: {
      statement,
    },

    creating: false,
    loading: false,
    isPublic: false,
  }),

  methods: {
    formatDate,
    fetchComments() {
      if (this.complaint) {
        this.loading = true;
        this.$axios
          .get(`/comments/${this.complaint}`)
          .then(({ data: comments }) => {
            this.comments = comments;
            this.comment = null;
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not load comments",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    makeComment() {
      this.creating = true;
      this.$axios
        .post(`/comments`, {
          text: this.comment,
          issue_id: this.complaint,
          type: this.isPublic ? "public" : "private",
        })
        .then(() => {
          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Comment made",
          });

          this.fetchComments();
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not make comment",
          });
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
  created() {
    this.fetchComments();
  },
};
</script>

<style lang="scss" scoped>
.comments {
  width: 90%;
  margin: 2em auto 2em;
  padding-top: 2em;

  border-top: 8px solid #d4ebd4;
}

.message {
  font-size: 0.9em;
  white-space: pre-line;
  padding: 1em 0.5em;
  margin-bottom: 0;

  background-color: #f6f8f6;
}
</style>
