import Complaints from "../../components/Complaint/Complaints";
import Complaint from "../../components/Complaint/Complaint";
import TreatedComplaints from "../../components/Complaint/TreatedComplaints";
import StudentComplaints from "../../components/Complaint/StudentComplaints";
import ComplaintsPinned from "../../components/Complaint/ComplaintsPinned";
import ComplaintsAssigned from "../../components/Complaint/ComplaintsAssigned";

const routes = [
  {
    path: "/complaints",
    name: "complaints",
    component: Complaints,
    meta: { requiresAuth: true, description: "All complaints" },
  },
  {
    path: "/treated",
    name: "treated",
    component: TreatedComplaints,
    meta: { requiresAuth: true, description: "Treated complaints history" },
  },
  {
    props: true,
    path: "/student-complaints/:registration",
    name: "student-complaints",
    component: StudentComplaints,
    meta: { requiresAuth: true, description: "Complaints by this student" },
  },
  {
    props: true,
    path: "/complaint/:ticket",
    name: "complaint",
    component: Complaint,
    meta: { requiresAuth: true, description: "Complaint details" },
  },
  {
    path: "/pinned",
    name: "pinned",
    component: ComplaintsPinned,
    meta: { requiresAuth: true, description: "Complaints you pinned" },
  },
  {
    path: "/assigned",
    name: "assigned",
    component: ComplaintsAssigned,
    meta: { requiresAuth: true, description: "Complaints assigned to you" },
  },
];

export default routes;
