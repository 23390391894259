<template>
  <div>
    <v-card max-width="520px" class="mx-auto mt-5">
      <div class="pa-5 d-flex align-center justify-space-between">
        <v-img class="shrink mr-2" contain src="/favicon.png" transition="scale-transition" width="60" />
        <v-spacer></v-spacer>
        <v-btn to="/" outlined>
          New Complaint
          <v-icon size="17px" class="ml-2">mdi-email-send-outline</v-icon>
        </v-btn>
      </div>

      <h2 class="py-2 text-center green white--text">
        <span v-if="found" class="text-uppercase px-5 d-flex align-center justify-space-between">
          <v-btn to="/follow-up" text>
            <v-icon size="17px">mdi-chevron-left</v-icon>
            Back
          </v-btn>
          <span><small>Ticket:</small> {{ formatTicket(complaint.ticket) }}</span>
        </span>
        <span v-else>Loading...</span>
      </h2>

      <v-card color="basil" flat>
        <v-card-text>
          <div v-if="found" class="pb-7">
            <h3 class="text-uppercase mb-1">
              <small>Subject: </small>
              {{ complaint.subject }}
            </h3>

            <h3 class="grey--text mb-5">
              <small v-if="complaint.attachments.length > 0">
                Attached
                {{ complaint.attachments.length }} {{ complaint.attachments.length > 1 ? "files" : "file" }}
              </small>
              <small v-else>No file attached</small>
            </h3>

            <p v-if="complaint.registration">
              Reg. No: {{ complaint.registration }}
            </p>

            <v-row class="complaint-tags mr-0">
              <v-chip outlined label class="ma-1 mr-2">
                <v-icon left> mdi-phone-outline </v-icon>
                {{ complaint.contact }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-chip class="ma-1 white--text" :color="`${complaint.status == 'treated' ? 'green darken-2' : 'pink'}`">
                {{ complaint.status == "treated" ? "Treated" : "Processing" }}
              </v-chip>
            </v-row>

            <v-card flat>
              <v-card-text>
                <h4 class="pink--text mb-3">Complaint</h4>
                <p class="message">{{ complaint.description }}</p>
              </v-card-text>
            </v-card>

            <div v-if="complaint.public_comments.length > 0">
              <v-divider class="my-3"></v-divider>

              <h3 class="text-center black--text mt-1 mb-4">Comments ({{ complaint.public_comments.length }})</h3>

              <div v-if="!complaint.permanently_closed" class="mb-6">
                <PublicComments :ticket="complaint.ticket" @comment="findComplaint" />
              </div>

              <v-card :class="comment.commenter == 'You' ? 'orange lighten-5' : ''" class="mb-2" flat
                v-for="(comment, index) in complaint.public_comments" :key="index">
                <v-card-subtitle>
                  <v-row class="pa-3">
                    <strong
                      :class="comment.commenter == 'You' ? 'grey--text text--lighten-1' : 'brown--text text--lighten-2'">{{
                        comment.commenter
                      }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn text x-small>
                      {{ formatDate(comment.created_at) }}
                    </v-btn>
                  </v-row>
                </v-card-subtitle>
                <v-card-text class="pb-5">
                  <p class="message">
                    {{ comment.text }}
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { formatDate, formatTicket } from "../tools/format";
import PublicComments from './includes/PublicComments.vue';

export default {
  data: () => ({
    found: false,
    loading: false,
    complaint: {}
  }),

  components: { PublicComments },

  methods: {
    formatDate,
    formatTicket,
    findComplaint() {
      this.loading = true;
      const { ticket } = this.$route.params;


      this.$axios
        .get(`/issues/ticket/${ticket.toUpperCase().split("-").join("")}`)
        .then(({ data: complaint }) => {
          this.complaint = complaint;

          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Complaint loaded.",
          });

          this.loading = false;
          this.found = true;
          this.ticket = null;
        })
        .catch(() => {
          this.loading = false;
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Complaint not found.",
          });

          this.$router.push({ name: 'follow' });
        });
    },
  },

  mounted() {
    this.findComplaint();
  }
};
</script>


<style lang="scss" scoped>
.complaint-tags {
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: #f7f0d2 !important;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 0.12em;

  // border-top: 1px solid #98c59b;
  // border-bottom: 1px solid #98c59b;
}

.comments {
  width: 80%;
  margin: 2em auto 2em;
  padding-top: 2em;

  border-top: 8px solid #d4ebd4;
}

.message {
  // font-size: 0.95em;
  white-space: pre-line;
  padding: 1em 0.75em;
  margin-bottom: 0;
  color: #222222;

  background-color: #f8f8f6;
}
</style>
