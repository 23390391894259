<template>
  <div>
    <v-card max-width="520px" class="mx-auto mt-5">
      <div class="pa-5 d-flex align-center justify-space-between">
        <v-img class="shrink mr-2" contain src="/favicon.png" transition="scale-transition" width="60" />
        <v-spacer></v-spacer>
        <v-btn to="/" outlined>
          New Complaint
          <v-icon size="17px" class="ml-2">mdi-email-send-outline</v-icon>
        </v-btn>
      </div>

      <h2 class="py-1 text-center green white--text text-uppercase">
        Follow-Up Complaint
      </h2>

      <v-card color="basil" flat>
        <v-card-text>
          <FollowUp />
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import FollowUp from "./includes/FollowUp";

export default {
  components: {
    FollowUp,
  },

  data() {
    return {
      tab: null,
      items: ["Open New Complaint", "Check Complaint"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<style scoped>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}
</style>
