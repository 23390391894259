<template>
  <div class="container" :class="showing ? 'show' : ''">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showing: false,
  }),
  methods: {
    showLoadingIndicator() {
      this.showing = true;
    },
    hideLoadingIndicator() {
      this.showing = false;
    },
  },
  mounted() {
    this.$bus.$on("request.initiated", this.showLoadingIndicator);
    this.$bus.$on("request.ended", this.hideLoadingIndicator);
  },
  beforeDestroy() {
    this.$bus.$off(["request.initiated", "request.ended"]);
  },
};
</script>

<style scoped>
.container {
  position: fixed;
  top: 5em;
  right: 12px;

  width: 6em;

  display: flex;
  justify-content: center;

  z-index: 5;
  background-color: rgba(222, 240, 230, 0.66);

  border-radius: 2em;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translateX(140%);
  opacity: 0;
}

.container.show {
  transform: translateX(0);
  opacity: 1;
}

.dot {
  width: 12px;
  height: 12px;
  border: 3px solid #64ec52;
  /* border: 2px solid white; */
  border-radius: 50%;
  float: left;
  margin: 0 5px;
  transform: scale(0);
  -webkit-animation: fx 1000ms ease infinite 0ms;
  animation: fx 1000ms ease infinite 0ms;
}
.dot:nth-child(2) {
  -webkit-animation: fx 1000ms ease infinite 300ms;
  animation: fx 1000ms ease infinite 300ms;
}
.dot:nth-child(3) {
  -webkit-animation: fx 1000ms ease infinite 600ms;
  animation: fx 1000ms ease infinite 600ms;
}
@-webkit-keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>