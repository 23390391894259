import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// So that we can navigate to the same route without errors
// Should we be doing this? Maybe not.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

import AuthRoutes from "./routes/auth";
import ComplaintRoutes from "./routes/complaint";
import StaffRoutes from "./routes/staff";
import NotificationRoutes from "./routes/notification";
import Home from "../components/Home";
import Follow from "../components/Follow";
import FollowTicket from "../components/FollowTicket";

const router = new VueRouter({
  mode: "history",
  routes: [
    ...AuthRoutes,
    ...ComplaintRoutes,
    ...StaffRoutes,
    ...NotificationRoutes,
    // {
    //   path: "/dashboard",
    //   name: "dashboard",
    //   component: Dashboard,
    //   meta: { requiresAdmin: true },
    // },
    // {
    //   props: true,
    //   path: "/examinations/:id",
    //   name: "examination",
    //   component: Examination,
    //   meta: { requiresAuth: true },
    // },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresVisitor: true,
        description: "Create a new complaint",
      },
    },
    {
      path: "/follow-up",
      name: "follow",
      component: Follow,
      meta: {
        requiresVisitor: true,
        description: "Follow up on complaint",
      },
    },
    {
      path: "/follow-up/:ticket",
      name: "follow-ticket",
      component: FollowTicket,
      meta: {
        requiresVisitor: true,
        description: "Follow up ticket",
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

export default router;
