import Notifications from "../../components/Notification/Notifications";

const routes = [
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
];

export default routes;
