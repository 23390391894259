<template>
  <div class="alert-container">
    <v-alert
      text
      v-model="alert"
      dismissible
      :color="alertColor"
      border="left"
      elevation="2"
    >
      <!-- icon="mdi-alert-rhombus-outline" -->
      <span class="ml-2 mr-3">{{ alertText }}</span>
    </v-alert>
  </div>
</template>
<script>
export default {
  data() {
    return {
      alert: false,
      alertText: "",
      alertColor: "",
    };
  },
  methods: {
    showAlert({ type = "default", message }) {
      this.alertColor = type;
      this.alertText = message;
      this.alert = true;
    },
  },
  created() {
    this.$bus.$on("alert", this.showAlert);
  },
  beforeDestroy() {
    this.$bus.$off("alert");
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  top: 5.5em;
  right: 1em;
  z-index: 9;
  position: fixed;
  // min-width: 30%;
  max-width: 500px;
}
</style>