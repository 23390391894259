<template>
  <div class="mt-5 ml-2 pb-7">
    <div v-if="created" class="mx-4">
      <h3 class="mb-3">We have received your complaint</h3>
      <p>
        Please use the ticket number below to follow it up. We usually treat complaints
        within 3 working days.
      </p>
      <v-divider class="my-3 mb-5"></v-divider>
      <div class="d-flex complaint-tags">
        <h1>
          <v-icon>mdi-ticket-outline</v-icon>
          {{ complaint.ticket | VMask("XXX-XXX") }}
        </h1>
        <v-spacer></v-spacer>
        <v-btn icon right @click="copyTicket">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </div>
    </div>
    <v-form v-else v-model="valid" class="pb-7 mr-4" name="newComplaintForm" @submit.prevent="openNewComplaint">
      <p class="ml-8 mb-7">
        Please only lodge complaints that have to do with the students’ portal. Matters that have nothing to do with the
        portal will not be attended to.
      </p>

      <v-text-field outlined label="Subject *" prepend-icon="mdi-tag-text-outline" :rules="rules.required"
        v-model="subject" counter="60"></v-text-field>

      <v-text-field outlined label="Contact phone or email *" prepend-icon="mdi-at" :rules="rules.required"
        hint="We will contact you with this email/number if need be" v-model="contact"></v-text-field>

      <v-text-field outlined label="Registration number (for students)" prepend-icon="mdi-card-account-details-outline"
        v-model="registration"></v-text-field>

      <v-textarea outlined name="input-7-4" label="Complaint description *" prepend-icon="mdi-text-box-outline"
        counter="300" :rules="[...rules.required, ...rules.statement]" v-model="description"></v-textarea>

      <p class="ml-8">
        If you have more than one file to attach, merge them using
        <v-btn href="https://www.ilovepdf.com/merge_pdf" outlined color="orange" target="_blank" x-small>This
          tool</v-btn>
        or others like it.
      </p>

      <v-file-input outlined accept="image/*,application/pdf" label="Document (optional)" placeholder="Attach document"
        :show-size="1000" prepend-icon="mdi-attachment" v-model="attachment" :rules="rules.attachment"></v-file-input>



      <div class="ml-8">
        <p>* mark required fields</p>
        <v-btn color="green" type="submit" class="white--text" :loading="creating" :disabled="!valid || creating">
          Submit
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { required, attachment, statement } from "../../tools/rules";

export default {
  data: () => ({
    valid: false,
    rules: {
      required,
      attachment,
      statement,
    },

    complaint: { ticket: "RES562" },

    subject: null,
    contact: null,
    description: null,
    attachment: null,
    registration: null,

    creating: false,
    created: false,
  }),

  methods: {
    openNewComplaint() {
      this.creating = true;

      var formData = new FormData();
      if (this.attachment) formData.append("attachment", this.attachment);
      formData.append("subject", this.subject);
      formData.append("contact", this.contact);
      formData.append("description", this.description);

      this.registration && formData.append("registration", String(this.registration).replace(/\s/g, '').toUpperCase());

      this.$axios({
        url: "/issues",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(({ data: complaint }) => {
          this.creating = false;
          this.complaint = complaint;
          this.created = true;
        })
        .then(() => {
          this.creating = false;
        });
    },
    copyTicket() {
      var input = document.createElement("textarea");
      input.value = this.complaint.ticket;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      input.remove();

      this.$bus.$emit("snackbar", {
        type: "info",
        message: "Ticket copied to clipboard",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.complaint-tags {
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: #f7f0d2 !important;
  margin-top: 1em;
  margin-left: 0.12em;

  h1 {
    line-height: 1;
    margin-top: 3px;
    user-select: none;
  }

  // border-top: 1px solid #98c59b;
  // border-bottom: 1px solid #98c59b;
}
</style>
