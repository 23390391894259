<template>
  <div>
    <Fetching v-if="loading" />
    <v-row v-else>
      <v-col cols="12">
        <div class="controls">
          <v-form v-if="issues.length > 0" @submit.prevent="searchComments" v-model="valid">
            <v-text-field full-width placeholder="Search list of complaints" hide-details append-icon="mdi-magnify"
              filled single-line v-model="term" clearable></v-text-field>
          </v-form>
          <p v-if="term" class="mt-1 search">
            <small>
              Showing {{ filteredComplaints.length }} results for "{{ term }}"
            </small>
          </p>
        </div>

        <v-data-table disable-pagination :headers="headers" :items="filteredComplaints" hide-default-footer
          class="elevation-1">
          <template v-slot:[`item.issue.id`]="{ item }">
            <small class="grey--text">{{ item.issue.id }}</small>
          </template>

          <template v-slot:[`item.issue.ticket`]="{ item }">
            <small style="white-space: nowrap;">
              {{ item.issue.ticket | VMask("XXX-XXX") }}
              <v-icon small v-if="isPinned(item.issue.id)" color="purple" title="You pinned this complaint">
                mdi-pin-outline
              </v-icon>
            </small>
          </template>

          <template v-slot:[`item.issue.status`]="{ item }">
            <v-chip x-small class="white--text text-uppercase"
              :color="item.issue.status == 'open' ? 'red--text lighten-1' : 'green--text'">
              {{ item.issue.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.issue.updated_at`]="{ item }">
            <small style="white-space: nowrap;">{{ formatDate(item.issue.updated_at) }}</small>
          </template>

          <template v-slot:[`item.issue.created_at`]="{ item }">
            <v-btn small text color="orange darken-2" :to="`complaint/${item.issue.ticket}`">
              Details
              <v-icon class="ml-1" small>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <div v-if="filteredComplaints.length > 0">
          <div class="mb-10 mt-10">
            <div class="text-center d-flex align-center justify-space-between">
              <span>Showing {{ from }} - {{ to }} of {{ count }} complaints</span>
              <v-pagination v-model="page" :length="last" :total-visible="5" @input="navigateToPage"></v-pagination>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Fetching from "../../utils/Fetching.vue";
import { formatDate } from "../../../tools/format";

export default {
  components: {
    Fetching,
  },

  props: {
    issues: {
      type: Array,
      default: () => []
    },
    pinned: {
      type: Array,
      default: () => []
    },
    current: { type: Number },
    last: { type: Number },
    from: { type: Number },
    to: { type: Number },
    count: { type: Number },
    alternative: { type: Boolean, default: false }
  },

  data: () => ({
    loading: false,
    term: "",


    valid: false,
    page: 1,
  }),

  watch: {
    current(value) {
      this.page = value;
    }
  },

  computed: {
    headers() {
      return [
        { text: "ID", value: "issue.id", sortable: false },
        { text: "Updated", value: "issue.updated_at" },
        { text: "Ticket", value: "issue.ticket" },
        { text: "Reg. No.", value: "issue.registration" },
        { text: "Subject", value: "issue.subject" },
        { text: "Status", value: "issue.status" },
        { text: "", value: "issue.created_at", sortable: false },
      ];
    },
    filteredComplaints: function () {
      const complaints = [...this.issues].filter((com) => {
        if (this.term) {
          const term = this.term.toLowerCase();
          return (
            com.issue.subject.toLowerCase().includes(term) ||
            com.issue.ticket.toLowerCase().includes(term) ||
            (com.issue.registration && com.issue.registration.toLowerCase().includes(term)) ||
            com.issue.description.toLowerCase().includes(term) ||
            com.issue.status.toLowerCase().includes(term)
          );
        } else return com;
      });

      return complaints;
    },
  },

  methods: {
    formatDate,
    searchComments() { },
    isPinned(id) {
      return !!this.pinned.find((pinned) => {
        return pinned.issue_id == id;
      });
    },
    navigateToPage(value) {
      this.$emit('paginate', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  top: 6em;
  margin-bottom: 1em;
}
</style>
