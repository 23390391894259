<template>
  <div>
    <v-card max-width="520px" class="mx-auto mt-5">
      <div class="pa-5 d-flex align-center justify-space-between">
        <v-img class="shrink mr-2" contain src="/favicon.png" transition="scale-transition" width="60" />

        <v-btn to="/follow-up" outlined> Follow-up Complaint </v-btn>
      </div>

      <h2 class="py-1 text-center green white--text text-uppercase">
        New Complaint
      </h2>

      <v-card color="basil" flat>
        <v-card-text>
          <OpenComplaint />
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import OpenComplaint from "./includes/NewComplaint";

export default {
  components: {
    OpenComplaint,
  },
};
</script>

<style scoped>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}
</style>
