var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('Fetching'):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"controls"},[(_vm.issues.length > 0)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchComments.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"full-width":"","placeholder":"Search list of complaints","hide-details":"","append-icon":"mdi-magnify","filled":"","single-line":"","clearable":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1):_vm._e(),(_vm.term)?_c('p',{staticClass:"mt-1 search"},[_c('small',[_vm._v(" Showing "+_vm._s(_vm.filteredComplaints.length)+" results for \""+_vm._s(_vm.term)+"\" ")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.filteredComplaints,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(item.id)+".")])]}},{key:"item.ticket",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("VMask")(item.ticket,"XXX-XXX"))+" "),(_vm.isPinned(item.id))?_c('v-icon',{attrs:{"small":"","color":"purple","title":"You pinned this complaint"}},[_vm._v(" mdi-pin-outline ")]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text text-uppercase",attrs:{"x-small":"","color":item.status == 'open' ? 'red--text lighten-1' : 'indigo--text text--darken-2'}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('small',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"orange darken-2","to":("/complaint/" + (item.ticket))}},[_vm._v(" Details "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,true)}),(_vm.filteredComplaints.length > 0)?_c('div',[_c('div',{staticClass:"mb-10 mt-10"},[_c('div',{staticClass:"text-center d-flex align-center justify-space-between"},[_c('span',[_vm._v("Showing "+_vm._s(_vm.from)+" - "+_vm._s(_vm.to)+" of "+_vm._s(_vm.count)+" complaints")]),_c('v-pagination',{attrs:{"length":_vm.last,"total-visible":5},on:{"input":_vm.navigateToPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }